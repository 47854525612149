import axios from './index';
const baseUrl = process.env.NODE_ENV === 'production'? '/api' : '/api';

const API = {
  StandardList: (data, formData) => axios.post(baseUrl + '/back/paper_standard/selectStandard', data, formData, true),
  AddStandard: (data, formData) => axios.post(baseUrl + '/back/paper_standard/insertStandard', data, true),
  EditStandard: (data, formData) => axios.post(baseUrl + '/back/paper_standard/updateStandard', data, true),
  DeleteStandard: (data, formData) => axios.post(baseUrl + '/back/paper_standard/deleteBatchByIds', data, true),
  BorrowStandard: (data, formData) => axios.post(baseUrl + '/back/paper_standard/borrowBook', data, true),
  ReturnStandard: (data, formData) => axios.post(baseUrl + '/back/paper_standard/returnBook', data, true),
  ExportRecord: (data, formData) => axios.download(baseUrl + '/back/paper_standard/exportRecord', data),
}
export default API
