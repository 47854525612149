<template>
  <router-view />
</template>
<script>
  export default ({
    created() {
      this.checkBrowsers();
    },
    methods: {
      checkBrowsers: function() {
        let ua = navigator.userAgent.toLocaleLowerCase();
        if(ua.match(/msie/) != null || ua.match(/trident/) != null) {
          this.$router.replace('/browser')
        }
      }
    }
  })
</script>

<style lang="scss">
@import './assets/css/common.scss';
#app {
  font-family: Microsoft YaHei, Microsoft YaHei-Bold, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1200px;
  min-height: 100vh;
  color: #606266;
  background: #f8f9fb;
}
</style>
