import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('@/layout/Index'),
    children: [
      { // 首页
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home')
      },
      { // 列表
        path: '/searchList',
        name: 'searchList',
        component: () => import('@/views/List')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
