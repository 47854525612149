
import 'babel-polyfill';
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import API from './require/api';
import store from './store'

const app = createApp(App);
app.config.globalProperties.$API = API;
app.config.globalProperties.baseUrl = '/api';

app.use(store).use(router).use(ElementPlus).mount('#app')
